// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import PropTypes from 'prop-types'
// import classNames from "classnames";

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Img from 'gatsby-image'
import { StaticQuery, graphql } from 'gatsby'

// import keygen from '@bodhi-project/components/lib/methods/keygen'

import Heart from 'react-feather/dist/icons/heart'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import './style.less'

import Link from '../../Link'

// import footerMenu from '../../../data/footerMenu.json'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    logoWithText: file(relativePath: { eq: "logos/logo-with-text.jpg" }) {
      ...max900
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Footer */
const Footer = props => {
  const { isDesktop } = props

  return (
    <StaticQuery
      query={query}
      render={data => (
        <footer>
          <div className="post-note">
            <p className="text">
              <span>
                GET IN TOUCH! Write to: hello@competency.systems
              </span>
            </p>
          

            <p className="text">
              <span>
                we enable next generation digital learning and assessment
              </span>
            </p>
            <p className="sub-text">
              <span>
                go2core.ai is an implementation of{' '}
                <Link to="https://www.competency.systems/">
                  competency.systems
                </Link>{' '}
                – a next-generation digital learning and assessment platform,
                designed by Digital Media Initiatives.
              </span>
            </p>
            <p className="year">
              Copyright © 2007 — 2021&nbsp;
              <Link to="https://www.dmi.systems/">
                Digital Media Initiatives
              </Link>
            </p>
            <p className="message">
              Website made with by&nbsp;
              <Heart size={14} />
              &nbsp;
              <Link to="https://www.xn--kla-1oa.org/">Kāla</Link>
            </p>
          </div>
        </footer>
      )}
    />
  )
}

Footer.propTypes = {
  location: PropTypes.object, // eslint-disable-line react/forbid-prop-types
}

// --------------------------------------------------------------------- Export
export default Footer
